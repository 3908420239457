const state = {
  wallets: [],
  walletMetaData: [],
}

const getters = {
  walletsGetter: ({ wallets }) => wallets,
  walletMetaDataGetter: ({ walletMetaData }) => walletMetaData,
}

const mutations = {
  setWallets: (state, payload) => (state.wallets = payload),
  setWalletMetaData: (state, payload) => (state.walletMetaData = payload),
}

const actions = {
  async getWallets({ commit }, payload) {
    const filters = payload && payload.filters ? { ...payload.filters } : {}
    await this.$axios.get('admin/wallets', { params: { ...filters } }).then((res) => {
      commit('setWallets', res.data.result.items)
      commit('setWalletMetaData', res.data.result.metadata)
    })
  },
}
export default { state, getters, mutations, actions }
