<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" />
    <div class="layout-sidebar" @click="onSidebarClick">
      <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
    </div>

    <div class="layout-main-container">
      <Breadcrumb :home="home" :model="breadcrumbItems" class="mb-2" />
      <div class="layout-main">
        <router-view />
      </div>
      <AppFooter />
    </div>

    <AppConfig :layout-mode="layoutMode" @layout-change="onLayoutChange" />
    <transition name="layout-mask">
      <div v-if="mobileMenuActive" class="layout-mask p-component-overlay"></div>
    </transition>
  </div>
</template>
<script>
import AppTopBar from './AppTopbar.vue'
import AppMenu from './AppMenu.vue'
import AppConfig from './AppConfig.vue'
import AppFooter from './AppFooter.vue'

export default {
  components: {
    AppTopBar: AppTopBar,
    AppMenu: AppMenu,
    AppConfig: AppConfig,
    AppFooter: AppFooter,
  },
  emits: ['change-theme'],
  data() {
    return {
      layoutMode: 'static',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menu: [
        {
          label: 'Home',
          items: [
            {
              label: 'Dashboard',
              icon: 'pi pi-fw pi-home',
              to: '/',
            },
          ],
        },
        {
          label: 'Pages',
          icon: 'pi pi-fw pi-clone',
          items: [
            { label: 'Teams', icon: 'pi pi-fw pi-users', to: '/teams' },
            { label: 'Ambassadors', icon: 'pi pi-fw pi-user', to: '/ambassadors' },
            { label: 'Admins', icon: 'pi pi-fw pi-user-plus', to: '/admins' },
            { label: 'Roles', icon: 'pi pi-fw pi-sliders-h', to: '/roles' },
            { label: 'Tasks', icon: 'pi pi-fw pi-list', to: '/tasks' },
            { label: 'Badges', icon: 'pi pi-fw pi-tag', to: '/badges' },
            { label: 'Programs', icon: 'pi pi-fw pi-file', to: '/programs' },
            { label: 'Question Categories', icon: 'pi pi-fw pi-question', to: '/question-categories' },
            { label: 'Wallets', icon: 'pi pi-fw pi-wallet', to: '/wallets' },
            { label: 'Invites', icon: 'pi pi-fw pi-envelope', to: '/invites' },
            { label: 'FAQ', icon: 'pi pi-fw pi-question-circle', to: '/faqs' },
            { label: 'Campaigns', icon: 'pi pi-fw pi-percentage', to: '/campaigns' },
            { label: 'Notification Templates', icon: 'pi pi-fw pi-bell', to: '/notification-templates' },
            { label: 'Places', icon: 'pi pi-fw pi-map-marker', to: '/places' },
            { label: 'Translation', icon: 'pi pi-fw pi-globe', to: '/translations' },
            { label: 'App Version', icon: 'pi pi-fw pi-arrow-circle-up', to: '/app-version' },
          ],
        },
      ],
    }
  },
  computed: {
    containerClass() {
      return [
        'layout-wrapper',
        {
          'layout-overlay': this.layoutMode === 'overlay',
          'layout-static': this.layoutMode === 'static',
          'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
          'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
          'layout-mobile-sidebar-active': this.mobileMenuActive,
          'p-input-filled': this.$primevue.config.inputStyle === 'filled',
          'p-ripple-disabled': this.$primevue.config.ripple === false,
        },
      ]
    },
    logo() {
      return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo.svg'
    },
  },
  watch: {
    $route() {
      this.menuActive = false
      this.$toast.removeAllGroups()
    },
  },
  beforeUpdate() {
    if (this.mobileMenuActive) this.addClass(document.body, 'body-overflow-hidden')
    else this.removeClass(document.body, 'body-overflow-hidden')
  },
  methods: {
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false
        this.mobileMenuActive = false
      }

      this.menuClick = false
    },
    onMenuToggle() {
      this.menuClick = true

      if (this.isDesktop()) {
        if (this.layoutMode === 'overlay') {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true
          }

          this.overlayMenuActive = !this.overlayMenuActive
          this.mobileMenuActive = false
        } else if (this.layoutMode === 'static') {
          this.staticMenuInactive = !this.staticMenuInactive
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive
      }

      event.preventDefault()
    },
    onSidebarClick() {
      this.menuClick = true
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false
        this.mobileMenuActive = false
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className)
      else element.className += ' ' + className
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className)
      else element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ')
    },
    isDesktop() {
      return window.innerWidth >= 992
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === 'static') return !this.staticMenuInactive
        else if (this.layoutMode === 'overlay') return this.overlayMenuActive
      }

      return true
    },
  },
}
</script>
<script setup>
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'

const breadcrumbItems = ref([{ label: '' }])

const route = useRoute()
const home = ref({
  icon: 'pi pi-home',
  to: '/',
})

watch(
  route,
  (val) => {
    console.log(val.matched)
    breadcrumbItems.value = val.matched
      .filter((bc) => bc.name !== 'app')
      .map((bc) => {
        return { label: bc.name.charAt(0).toUpperCase() + bc.name.slice(1), to: bc.path }
      })
  },
  { immediate: true }
)
</script>

<style lang="scss">
@import './App.scss';
</style>
