const state = {
  invites: [],
  inviteMetaData: [],
  duplicatedInvites: [],
}
const getters = {
  invitesGetter: ({ invites }) => invites,
  inviteMetaDataGetter: ({ inviteMetaData }) => inviteMetaData,
  duplicatedInvitesGetter: ({ duplicatedInvites }) => duplicatedInvites,
}

const mutations = {
  setInvites: (state, payload) => (state.invites = payload),
  setInvite: (state, payload) => state.invites.push(payload),
  setInviteMetaData: (state, payload) => (state.inviteMetaData = payload),
  setDuplicatedInvites: (state, payload) => (state.duplicatedInvites = payload),
}

const actions = {
  async createInvite({ commit }, payload) {
    await this.$axios.post('admin/invites', payload).then((res) => {
      commit('setInvite', res.data.result)
    })
  },
  async getInvites({ commit }, payload) {
    const filters = payload && payload.filters ? { ...payload.filters } : {}

    await this.$axios.get('admin/invites', { params: { ...filters } }).then((res) => {
      commit('setInviteMetaData', res.data.result.metadata)
      commit('setInvites', res.data.result.items)
    })
  },
  async getDuplicateInvites({ commit }, payload) {
    const filters = payload && payload.filters ? { ...payload.filters } : {}

    await this.$axios.get('admin/duplicate-invites', { params: { ...filters } }).then((res) => {
      commit('setDuplicatedInvites', res.data.result)
    })
  },
  async submitDuplicateInvites({ commit, dispatch }, { id, payload }) {
    await this.$axios.put(`admin/duplicate-invites/${id}`, payload)
    dispatch('getDuplicateInvites')
  },
  updateInvite({ commit }, payload) {
    this.$axios.put(`admin/invites/${payload.id}`, payload).then((res) => {
      console.log(res)
    })
  },
}
export default { state, getters, mutations, actions }
