const state = {
  appVersion: [],
  appMetaData: [],
}
const getters = {
  appVersionGetter: ({ appVersion }) => appVersion,
  appMetaDataGetter: ({ appMetaData }) => appMetaData,
}

const mutations = {
  setAppVersion: (state, payload) => (state.appVersion = payload),
  setDeleteVersion: (state, id) => {
    state.appVersion = state.appVersion.filter((item) => item.id !== id)
  },
  setUpdateVersion: (state, payload) => {
    state.appVersion = state.appVersion.map((version) => {
      if (version.id === payload.id) {
        return Object.assign({}, version, payload)
      }
      return version
    })
  },
  setVersionMetaData: (state, payload) => (state.appMetaData = payload),
}

const actions = {
  async createVersion({ commit }, payload) {
    await this.$axios.post('admin/app-versions', payload).then((res) => {
      const newVersion = res.data.result
      commit('setAppVersion', [...state.appVersion, newVersion])
    })
  },
  async getVersion({ commit }, payload) {
    const filters = payload && payload.filters ? { ...payload.filters } : {}
    await this.$axios.get('admin/app-versions', { params: { ...filters } }).then((res) => {
      commit('setVersionMetaData', res.data.result.metadata)
      commit('setAppVersion', res.data.result.items)
    })
  },
  async updateVersion({ commit }, payload) {
    await this.$axios.put(`admin/app-versions/${payload.id}`, payload).then((res) => {
      commit('setUpdateVersion', payload)
    })
  },
  async deleteVersion({ commit }, id) {
    await this.$axios.delete(`admin/app-versions/${id}`).then((res) => {
      commit('setDeleteVersion', id)
    })
  },
}
export default { state, getters, mutations, actions }
