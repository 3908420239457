const state = {
  ambassadors: [],
  ambassadorMetaData: [],
  ambassadorsCreatedAtSort: -1,
  ambassadorById: null,
}
const getters = {
  ambassadorsGetter: ({ ambassadors }) => ambassadors,
  ambassadorMetaDataGetter: ({ ambassadorMetaData }) => ambassadorMetaData,
  ambassadorByIdGetter: ({ ambassadorById }) => ambassadorById,
}

const mutations = {
  setAmbassadors: (state, payload) => (state.ambassadors = payload),
  setBulkAmbassadors: (state, payload) => state.ambassadors.push(...payload),
  setAmbassador: (state, payload) => {
    if (state.ambassadorsCreatedAtSort === -1) {
      state.ambassadors.unshift(payload)
    } else if (state.ambassadorsCreatedAtSort === -1) {
      state.ambassadors.push(payload)
    } else {
      state.ambassadors.unshift(payload)
    }
  },
  setAmbassadorById: (state, payload) => (state.ambassadorById = payload),
  setAmbassadorMetaData: (state, payload) => (state.ambassadorMetaData = payload),
  setUpdateAmbassador: (state, payload) => {
    state.ambassadors = state.ambassadors.map((ambassador) => {
      if (ambassador.id === payload.id) {
        return Object.assign({}, ambassador, payload)
      }
      return ambassador
    })
  },
  setDeleteAmbassador: (state, payload) => {
    state.ambassadors = state.ambassadors.filter((ambassador) => ambassador.id !== payload)
  },
  setAmbassadorsCreatedAtSort: ({ state, payload }) => (state.ambassadorsCreatedAtSort = payload),
}

const actions = {
  async createAmbassador({ commit }, payload) {
    await this.$axios.post('admin/ambassadors', payload).then((res) => {
      commit('setAmbassador', { ...payload, id: res.data.result.id })
    })
  },
  async getAmbassadors({ commit }, payload) {
    const filters = payload && payload.filters ? { ...payload.filters } : {}
    await this.$axios.get('admin/ambassadors', { params: { ...filters } }).then((res) => {
      commit('setAmbassadorMetaData', res.data.result.metadata)
      if (payload && payload.bulk) commit('setBulkAmbassadors', res.data.result.items)
      else commit('setAmbassadors', res.data.result.items)
    })
  },
  async getAmbassadorById({ commit }, payload) {
    await this.$axios.get(`admin/ambassadors/${payload.id}`, { params: { ...payload.params } }).then((res) => {
      commit('setAmbassadorById', res.data.result)
    })
  },
  async updateAmbassador({ commit }, payload) {
    await this.$axios.put(`admin/ambassadors/${payload.id}`, payload).then((res) => {
      commit('setUpdateAmbassador', payload)
    })
  },
  deleteAmbassador({ commit }, payload) {
    this.$axios.delete(`admin/ambassadors/${payload}`).then((res) => {
      commit('setDeleteAmbassador', payload)
    })
  },
  async verifiedAmbassador({ commit }, payload) {
    await this.$axios.post(`admin/ambassadors/verify`, { ids: payload })
  },
}
export default { state, getters, mutations, actions }
